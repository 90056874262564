<script setup lang="ts">
import Api from "~/api/api";
const localePath = useLocalePath();
const { t } = useI18n();

const isOpen = ref(false);

let links = computed(() => {
  return [
    {
      label: t("layouts.default.header.links.home"),
      icon: "i-heroicons-book-open",
      to: localePath("/"),
    },
    {
      label: t("layouts.default.header.links.servers"),
      icon: "i-heroicons-square-3-stack-3d",
      to: localePath("/servers"),
    },
    // {
    //   label: t("header.links.ranks"),
    //   icon: "i-heroicons-rocket-launch",
    //   to: localePath("/ranks"),
    // },
    // {
    //   label: t("header.links.contact"),
    //   icon: "i-heroicons-rocket-launch",
    //   to: localePath("/contact"),
    // },
    // {
    //   label: t("header.links.shop"),
    //   icon: "i-heroicons-rocket-launch",
    //   to: localePath("/shop"),
    // },
  ];
});

// const items = [
//   [
//     {
//       label: "Dashboard",
//       icon: "i-heroicons-pencil-square-20-solid",
//       shortcuts: ["G","D"],
//       click: () => {
//         navigateTo(localePath("/dashboard"));
//       },
//     },
//   ],
//   [
//     {
//       label: "Activate",
//       icon: "i-heroicons-archive-box-20-solid",
//       click: () => {
//         isOpen.value = true;
//       },
//     },
//     {
//       label: "Sign out",
//       icon: "i-heroicons-arrow-left-on-rectangle",
//       click: () => {
//         document.cookie.split(";").forEach(function (c) {
//           document.cookie = c
//             .replace(/^ +/, "")
//             .replace(
//               /=.*/,
//               "=;expires=" + new Date().toUTCString() + ";path=/"
//             );
//         });
//         window.location.href = localePath("/");
//       },
//     },
//   ],
// ];

const profile = ref();

const items = computed(() => {
  return [
    [
      {
        label: "激活卡密",
        icon: "i-heroicons-archive-box-20-solid",
        click: () => {
          isOpen.value = true;
        },
      },
      {
        label: "退出登录",
        icon: "i-heroicons-arrow-left-on-rectangle",
        click: () => {
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(
                /=.*/,
                "=;expires=" + new Date().toUTCString() + ";path=/"
              );
          });
          window.location.href = localePath("/");
        },
      },
    ],
  ];
});

const { data } = await useAsyncData(
  async () => {
    const session_tokenCookie = useCookie<string>("session_token", {
      readonly: true,
    });
    const session_token = session_tokenCookie.value;
    if (!session_token) {
      return;
    }
    const res = await Api.profile();
    const user = res.data.data;
    if (user) {
      if (!items.value[1]) {
        items.value.push([
          {
            label: "个人主页",
            icon: "i-heroicons-user-20-solid",
            click: () => {
              navigateTo(localePath("/profile"));
            },
          },
        ]);
      }
      if (user.is_admin || user.is_reseller) {
        if (!items.value[2]) {
          items.value.push([
            {
              label: "Dashboard",
              icon: "i-heroicons-pencil-square-20-solid",
              click: () => {
                navigateTo(localePath("/dashboard"));
              },
            },
          ]);
        }
      }
      if (links.value.length === 2) {
        links.value.push({
          label: "个人主页",
          icon: "i-heroicons-user-20-solid",
          to: localePath("/profile"),
        });
      }
      return user;
    }
  },
  { server: false, watch: [] }
);

const key = ref("");
const inviter_username = ref("");
const toast = useToast();

const activate = () => {
  Api.activate(key.value, inviter_username.value).then((res) => {
    if (res.data.code === 0x0) {
      toast.add({ title: "卡密已激活" });
      isOpen.value = false;
    } else {
      toast.add({ title: "激活失败", color: "red" });
    }
  });
};
</script>

<template>
  <view class="w-screen h-screen flex flex-col relative">
    <UModal v-model="isOpen">
      <div class="p-4">
        <div class="text-lg font-bold">激活卡密</div>
        <view class="mt-4">请输入您的激活码：</view>
        <UInput class="mt-4" v-model="key" />
        <UInput
          class="mt-4"
          v-model="inviter_username"
          placeholder="邀请者用户名(非必填)"
        />
        <UButton class="mt-4" label="激活" @click="activate" />
      </div>
    </UModal>
    <UHeader :links="links" class="grow-0">
      <template #logo>
        <UAvatar :src="data?.avatar || '/logo.png'" size="md" alt="Website logo" format="webp"/>
      </template>

      <template #right>
        <!-- <UColorModeButton /> -->
        <switchLanguage />
        <view v-if="!data">
          <UButton
            :to="localePath('/login')"
            class="ml-4"
            :label="t('layouts.default.header.buttons.login')"
            variant="solid"
          />
          <UButton
            :to="localePath('/register')"
            variant="outline"
            color="white"
            class="ml-4"
          >
            {{ t("layouts.default.header.buttons.register") }}
          </UButton>
        </view>
        <view v-else>
          <UDropdown
            :items="items"
            mode="hover"
            :popper="{ placement: 'bottom-start' }"
          >
            <UButton
              color="white"
              :label="data.username"
              trailing-icon="i-heroicons-chevron-down-20-solid"
            />
          </UDropdown>
        </view>
      </template>
    </UHeader>
    <slot class="grow" />
  </view>
</template>
